import React, { memo } from 'react';
import { graphql } from 'gatsby';
import { MainPage } from '../templates/MainPage';
import SeoText from 'components/SeoText';
import { Helmet } from "react-helmet"

const SemkaKoncertov = () => {
  return (
    <>
      <Helmet>
        <title>Съемка концертов в Москве цена от студии Elandic</title>
        <meta name="description" content="Съемка концертов цена в Москве услуги под ключ от студии Elandic. Заказать услуги профессиональной записи шоу концертов стоимость от видеопродакшена." />
        <meta name="keywords" content="съемка концертов, москва, видео, шоу, профессиональная, репортажная" />
      </Helmet>
      <MainPage>
        <SeoText title='Съемка концертов'>
          <p>Ваше выступление - это не просто концерт, это событие, полное эмоций, ярких впечатлений и незабываемой атмосферы. И чтобы запечатлеть этот момент в полной красе, нужна команда профессионалов, способная не только снимать, но и создавать историю, которая будет радовать вас и ваших поклонников долгие годы.</p>

          <p>Видеопродакшн Elandic - ваш лучший выбор для съемки концертов в Москве. Мы предлагаем полный комплекс услуг по профессиональной видеосъемке концертов: от репортажной съемки до видеомонтажа с использованием специальных эффектов и графики.</p>

          <p>Наши операторы и монтажеры - настоящие мастера своего дела, обладающие богатым опытом работы с концертами и шоу-проектами. Они используют только современное оборудование, чтобы создать высококачественную видеозапись.</p>

          <p>Мы учитываем все ваши пожелания и особенности мероприятия. Хотите динамичный репортаж или завораживающий клип? Мы подберем оптимальный вариант съемки и монтажа, который отразит атмосферу вашего концерта.</p>

          <p>Мы предлагаем гибкую систему цен, которая зависит от формата съемки, длительности мероприятия, и количества операторов. Вы можете выбрать оптимальный вариант под свой бюджет.</p>

          <p>Мы имеем огромный опыт работы с различными жанрами и форматами. Мы используем только профессиональное оборудование от ведущих производителей, что гарантирует отличное качество видеозаписи, и всегда идем навстречу клиентам и готовы реализовать любые их идеи.</p>

          <p>Хотите заказать съемку концерта в Москве и запечатлеть ваше выступление в полной красе? Тогда свяжитесь с нами и мы с удовольствием ответим на все ваши вопросы и поможем выбрать оптимальный вариант съемки и монтажа.</p>

        </SeoText>
      </MainPage>
    </>
  );
};

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: {
        language: { eq: $language }
        ns: { in: ["mainPage", "common", "layout"] }
      }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

export default memo(SemkaKoncertov);
